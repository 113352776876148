import React, { useState, useEffect, useMemo } from "react";
import useUI from "hooks/ui.hook";
import { Bell } from "react-feather";
import firebase from "firebase/app";
import 'firebase/firestore';
import DropDown from "lib/components/DropDown";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "lib/components";
import styles from "../assets/scss/components/notification.module.scss";
import { getNotificationsForEtab_rt, updateNotificationById } from "services/ressources/notification";
import { useHistory } from "react-router-dom";
import notificationsStore from "store/notificationsStore";
import { Row } from "reactstrap";
import ConciergeBellIcon  from "../lib/assets/svg/ConciergeBellGreen.svg";
import Utensils from "../lib/assets/svg/UtensilsBlue.svg";
import { Gift, MoreVertical, Tool, Calendar } from "react-feather";
import { getUserById, getUsersByEtab } from "services/ressources/user";
import InformationNotifModal from "pages/Major/Notification/Components/InformationNotifModal";

const firestore = firebase.firestore;

const Notification = () => {
  const [ui] = useUI();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const notifications = notificationsStore((state) => state.notifications);
  const sortedNotifications = notifications.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds).filter(notification => notification.data);
  const [usersList, setUsersList] = useState([]);
  const [notifSavingCurrent,setNotifSavingCurrent] =useState({});
  const notificationRequired = notificationsStore((state) => state.notificationRequired);
  const nombreNotif = notificationsStore((state)=>state.nombreNotif);

  const isAutourDeLHumain = ui?.establishment?.uid === "ej81AIfhGuj42PkD3WBO" || ui?.establishment?.uid === "A1LKT1bK4Aj4TAaqPsel";

  const userIsCollab = useMemo(() => {
    return ui.user.role === "employee";
  }, [ui.user.role]);

  useEffect(() => {
    setNotifSavingCurrent(notificationRequired);
  },[notificationRequired]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await getUsersByEtab({ etabId: ui.establishment.uid });
        setUsersList(res);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [ui]);

  const titleCategoryAction = {
    animation: {
      delete: `Annulation participation ${isAutourDeLHumain ? "Atelier" : "Animation"}`,
      update: `Modification participation ${isAutourDeLHumain ? "Atelier" : "Animation"}`,
      add: `Inscription ${isAutourDeLHumain ? "Atelier" : "Animation"}`,
    },
    service: {
      delete: "Annulation réservation Service",
      update: "Modification réservation Service",
      add: "Réservation Service",
    },
    menu: {
      delete: "Annulation réservation Menu",
      update: "Modification réservation Menu",
      add: "Réservation Menu",
    },
    intervention: {
      delete: "Suppression demande Intervention",
      update: "Modification demande Intervention",
      add: "Nouvelle demande Intervention"
    },
    birthday: "Nous souhaitons un anniversaire aujourd'hui!"
  };

  const generateBody = (notifData) => {
    let userName = "";
    let date = "";
    if (notifData?.data) {
      const user = usersList.find(user => user.uid === notifData.data.userId);
      if(user) userName = user.name +" "+user.surname;
      else userName = "Utilisateur inconnu";
    } else {
      userName = "Utilisateur inconnu";
    }
    const dateObject = new Date(notifData?.data?.date?.seconds * 1000);
    const dateDaymonth = dateObject.toLocaleDateString("fr-FR", { day: "2-digit", month: "short" });
    date = dateDaymonth;
    switch (notifData.type) {
      case "animation":
        switch (notifData.action) {
          case "delete":
            return `De ${userName}. Evènement "${notifData?.data?.name}" du ${date} à ${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}.`;
          case "update":
            return `De ${userName}. Evènement "${notifData?.data?.name}" du ${date} à ${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}.`;
          case "add":
            return `De ${userName}. Evènement "${notifData?.data?.name}" du ${date} à ${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}.`;
          default:
            return "Action inconnue";
        }
      case "service":
        switch (notifData.action) {
          case "delete":
            return `De ${userName}. Prestataire "${notifData?.data?.name}" du ${date} à ${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}.`;
          case "update":
            return `De ${userName}. Prestataire "${notifData?.data?.name}" du ${date} à ${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}.`;
          case "add":
            return `De ${userName}. Prestataire "${notifData?.data?.name}" du ${date} à ${dateObject.getHours().toString().padStart(2, "0")}:${dateObject.getMinutes().toString().padStart(2, "0")}.`;
          default:
            return "Action inconnue";
        }
      case "menu":
        switch (notifData.action) {
          case "delete":
            return `De ${userName}, pour le ${notifData?.data?.name} du ${date}.`;
          case "update":
            return `De ${userName}, pour le ${notifData?.data?.name} du ${date}.`;
          case "add":
            return `De ${userName}, pour le ${notifData?.data?.name} du ${date}.`;
          default:
            return "Action inconnue";
        }
      case "intervention":
        switch (notifData.action) {
          case "delete":
          return `De ${userName}.`;
          case "update":
            return `De ${userName}.`;
          case "add":
            return `De ${userName}. `;
          default:
            return "Action inconnue";
        }
      case "birthday":
        switch (notifData.action) {
          case "add":
            return ;
          default:
            return `Nous souhaitons l'anniversaire de ${userName} aujourd'hui!`;
        }
      default:
        return "Type de notification inconnu";
    }
  };

  if (!ui.user.establishment) return <></>;
  // const notifToShow = notif.type === "anniversaire" ? userDataNotif.anniversaire : userDataNotif[notif.type][notif.action];

  return (
    <span style={{ marginRight: 20, marginLeft: 10, position: "relative" }}>
      <Bell onMouseEnter={() => setIsOpen(true)} 
        size={32} style={{ paddingRight: 5, cursor: "pointer",color:"#552261" }} onClick={() => {
          setIsOpen(false);
          if(!userIsCollab){
            history.push("/dashboard/notificationCenter");
          }
        }} />
      {nombreNotif?.all && nombreNotif?.all !== 0    ?
        <span style={{
          backgroundColor: "red",
          paddingInline:sortedNotifications.filter((i) => (!i.seenBy.includes(ui.user.uid))).length <10 ? "5px":"2px",
          height: 20,
          display:"flex",
          alignItems:"center",
          justifyContent:"center",
          borderRadius: 20,
          border: "2px solid #f6f7fb",
          position: "absolute",
          top: -8,
          right: sortedNotifications.filter((i) => (!i.seenBy.includes(ui.user.uid))).length <10 ? -1 :-8,
          fontSize:"12px",
          fontWeight:800,
          color:"white"
        }}>{nombreNotif?.all >20 ? "20+" : nombreNotif.all}</span>
        : null}
      <DropDown
        isOpen={isOpen}
        className="dropdown-menu-right"
        style={{ width: 320, right: 0 }}
        toggle={() => setIsOpen(false)}>
        <h5 style={{ userSelect: "none" }}>Notifications</h5>
        <div className={styles.body}>
          {sortedNotifications.filter(i => !i.seenBy.includes(ui.user.uid)).map((notifData) => {
            const notifToHandle = notifData.type === "birthday" ? notifSavingCurrent.birthday :  notifSavingCurrent[notifData.type]?.[notifData.action] ;
            const title = notifData.type === "birthday" ? titleCategoryAction[notifData.type] : titleCategoryAction[notifData.type]?.[notifData.action] ? titleCategoryAction[notifData.type]?.[notifData.action] : "pas d'informations sur cette notification";
            const body = generateBody(notifData);
            if (notifData.data && notifToHandle) {
              return (
                <div className={styles.notif} key={notifData.uid} onClick={() => setModalOpen(notifData)}>
                  <div style={{ flex: 1 }}>

                    <div style={{ overflowWrap: "break-word", display: "flex", gap: "4px" }}>
                      {notifData.type === "animation" && (<Calendar size={20} color="#EFA55C" />)}
                      {notifData.type === "service" && (
                        <img src={ConciergeBellIcon} color="#99E36E" style={{ width: "20px", height: "20px", stroke: "#99E36E" }}></img>
                      // <ConciergeBellIcon style={{ width: "20px", height: "20px", stroke: "#99E36E" }} />
                      )}
                      {notifData.type === "menu" && (
                        <img src={Utensils} color="#5CADDB" style={{ width: "20px", height: "20px", stroke: "#5CADDB" }}></img>
                      // <Utensils style={{ width: "20px", height: "20px", stroke: "#5CADDB" }} />
                      )}
                      {notifData.type === "intervention" && (<Tool size={20} color="#EFD900" />)}
                      {notifData.type === "birthday" && (<Gift size={20} color="#DF7189"></Gift>)}
                      <h6>{title}</h6>
                    </div>
                    <p style={{ textAlign: "justify" }}>
                      {body+ " Voir plus..."}
                      {/* {body?.length > 150 ? " voir plus..." : null} */}
                    </p>
                  </div>
                </div>
              );
            }

          })}
          {sortedNotifications.filter(i => !i.seenBy.includes(ui.user.uid)).length === 0 ?
            <span>Pas de nouvelle notification</span>
            : null}
        </div>
      </DropDown>
      <InformationNotifModal isOpen={!!modalOpen} onClose={() => { setModalOpen(null); }} eventOpen={modalOpen}></InformationNotifModal>
      {/* <Modal isOpen={modalOpen} toggle={() => { setModalOpen(false); }} size="large">
        <ModalHeader>{modalOpen.title}</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: modalOpen.body }}></div>
        </ModalBody>
        <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
          <Button color="secondary" onClick={() => setModalOpen(false)}>Retour</Button>
          <Button onClick={() => { _markRead(modalOpen.uid); setModalOpen(false); }} style={{ marginRight: 0 }}>Marquer comme lu</Button>
        </ModalFooter>
      </Modal> */}
    </span>
  );
};

export default Notification;