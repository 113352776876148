import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createContext, useReducer, useContext } from "react";
import firebase from "firebase/app";
import 'firebase/firestore';
import moment from "moment";
import useUI from "hooks/ui.hook";
import { useParams, useHistory } from "react-router-dom";
import userStore from "../../../store/userStore";
import { getUsersByEtab } from "../../../services/ressources/user";

const firestore = firebase.firestore;

const Context = createContext();

const Default = {
  uid: null,
  ready: false,
  title: "",
  articles: [],
  pages: [],
  step: 0,
  color: "#D26F47",
  publication: null,
  editionDate: new Date(),
  settings: null,
  //for content editor
  selectedPage: null,
  selectedField: null,
  selectedContent: null,
  users: [],
};

function Reducer(state, action) {
  switch (action.type) {
    case "clear": return ({ ...Default });
    case "setReady": return ({ ...state, ready: action.ready });
    case "setStep": return ({ ...state, step: action.step });
    case "addArticle": return ({ ...state, articles: [...state.articles, action.article] });
    case "removeArticle": return ({ ...state, articles: state.articles.filter(i => i.uid !== action.article.uid) });
    case "setArticles": return ({ ...state, articles: action.articles });
    case "addPage": return ({ ...state, pages: [...state.pages, { uid: state.pages.length, slug: action.pageType.slug, title: "" }] });
    case "removePage":
      // on gère le cas où il y a une duplication de pages. Si il y a plusieurs pages avec le même id, on n'en supprime qu'une (de préférence la dernière).
      const pageIndex = state?.pages?.findLastIndex(p => p.uid === action.uid);
      if (pageIndex === -1) {
        return state; // Aucun changement si la page n'est pas trouvée
      }
      return {
        ...state,
        pages: [
          ...state.pages.slice(0, pageIndex),
          ...state.pages.slice(pageIndex + 1)
        ]
      };
    //return ({...state, pages: state.pages.filter(p => p.uid !== action.uid)});
    case "setPage": return ({ ...state, pages: state.pages.map(p => p.uid === action.page.uid ? { ...p, ...action.page } : p) });
    case "setPages": return ({ ...state, pages: action.pages });
    case "setSelectedPage": return ({ ...state, selectedPage: action.page });
    case "setSelectedField": return ({ ...state, selectedField: action.field });
    case "setSelectedContent": return ({ ...state, selectedContent: action.contentType });
    case "setTitle": return ({ ...state, title: action.title });
    case "setProperty": return ({ ...state, [action.property]: action.value });
    default: return { ...state };
  }
}

const Provider = ({ children }) => {
  const [ctx, dispatch] = useReducer(Reducer, Default);
  const [ui] = useUI();
  const { uid } = useParams();
  const history = useHistory();

  const usersState = userStore(state => state.users);
  const setUsersState = userStore(state => state.setUsers);

  useEffect(() => {
    if (ctx.users.length === 0) {
      (async () => {
        setUsersState(await getUsersByEtab({ etabId: ui.user.establishment }));
      })();
    }
  }, []);

  useEffect(() => {
    if (ui.establishment.gazettesSettings && ctx.settings === null) {
      dispatch({ type: "setProperty", property: "settings", value: ui.establishment.gazettesSettings });
    }
  }, [ui.establishment, ctx.settings]);

  function hasDuplicateUIDs(list) {
    let seenUIDs = new Set();
    let duplicateFound = false;

    list.forEach(item => {
      if (seenUIDs.has(item.uid)) {
        duplicateFound = true;
      }
      seenUIDs.add(item.uid);
    });

    return duplicateFound;
  }

  function ensureUniqueUIDs(list) {
    if (!hasDuplicateUIDs(list)) return list;

    let seenUIDs = new Set();
    let maxUID = Math.max(...list.map(item => item.uid));

    list.forEach(item => {
      while (seenUIDs.has(item.uid)) {
        item.uid = ++maxUID; // Assigne un nouvel UID unique
      }
      seenUIDs.add(item.uid);
    });

    return list;
  }


  useEffect(() => {

    if(hasDuplicateUIDs(ctx.pages)){
      const _newPages = ensureUniqueUIDs(ctx.pages);
      dispatch({ type: "setPages", pages: _newPages });
    }

  }, [ctx.pages]);
  //autosave
  useEffect(() => {
    (async () => {
      if (!ctx.ready) return;
      try {
        if (uid) {
          await firestore().collection("establishments").doc(ui.user.establishment)
            .collection("gazettes").doc(uid).update({
              title: ctx.title && ctx.title.length > 0 ? ctx.title : "Gazette du " + moment().format("DD/MM/YYYY HH:mm"),
              publication: ctx.publication,
              articles: ctx.articles.map(i => JSON.stringify({
                ...i,
                date: moment(i.date).utc(),
                updatedAt: moment(i.updatedAt).utc(),
              })),
              pages: ctx.pages.map(JSON.stringify),
              editionDate: ctx.editionDate ?? moment().toString(),
              updatedAt: new Date(),
              color: ctx.color,
            });
        } else {
          console.log("here", ctx);
          let res = await firestore().collection("establishments").doc(ui.user.establishment).collection("gazettes").add({
            title: ctx.title && ctx.title.length > 0 ? ctx.title : "Gazette du " + moment().format("DD/MM/YYYY HH:mm"),
            publication: ctx.publication,
            articles: ctx.articles.map(i => JSON.stringify({
              ...i,
              date: moment(i.date).utc(),
              updatedAt: moment(i.updatedAt).utc()
            })),
            pages: ctx.pages.map(i => JSON.stringify(i)),
            editionDate: ctx.editionDate ?? moment().toString(),
            createdAt: new Date(),
            updatedAt: new Date(),
            color: ctx.color,
          });
          history.replace(`/dashboard/gazette/${res.id}/edit`);
        }

      } catch (e) {
        console.error(e);
      }
    })();
  }, [ctx, uid]);

  return (
    <Context.Provider value={[{ ...ctx, users: usersState }, dispatch]}>
      {children}
    </Context.Provider>
  );
};

const useGazette = () => useContext(Context);

//TODO Definir les proptypes 

Provider.propTypes = {
  children: PropTypes.node,
};


export default useGazette;
export { Provider, Context };