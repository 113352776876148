import React, { useState, useEffect, useMemo, useCallback } from "react";
import useUI from "hooks/ui.hook";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  FormGroup,
  Select,
  Tag
} from "lib/components";
import PropTypes from "prop-types";
import deepEqual from "deep-equal";
import { ModalInformation, CategorySelector } from "../../../router_components";
import { useMenuReservation } from "../../../router_context";
import {findSubscriptionForDate} from "helpers/subscription"

import deepClone from "helpers/deepClone";
import styles from "../../../Basic/Assets/scss/modalReservation.module.scss";
import styles2 from "../scss/modalReservation.module.scss";
import { getDefaultRepas } from "../../../../../Menu/helpers/operations";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faUserMinus } from "@fortawesome/free-solid-svg-icons";
import { sendNotifToCentreNotif } from "services/ressources/notification";
import { SignatureDisplay, GuestSelectorModalStore } from "../../../router_components";

const colors = ["#8c66dc", "#dc6681", "#00bfb2", "#DC965A", "#81171B"];
const colorUnite = "#123865";

const ModalReservation = (props) => {
  const { 
    type = "resident",
  } = props;
  const [ui] = useUI();
  const [ctx, dispatch] = useMenuReservation();

  const template = ui.establishment.template;
  const templateSubscription = ui.establishment.templateSubscription2;

  const [reservation, setReservation] = useState([]);
  const [hasPaid, setHasPaid] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const [page, setPage] = useState(0);

  const { 
    isOpen: isGuestSelectorModalOpen, 
    setIsOpen: setIsGuestSelectorModalOpen,
    props: guestSelectorModalProps,
    setProps: setGuestSelectorModalProps
  } = GuestSelectorModalStore((state) => ({ 
    isOpen: state.isOpen, 
    setIsOpen: state.setIsOpen,
    props: state.props,
    setProps: state.setProps
  }));

  const getResa = (type) => {
    if(type === "resident") {
      const userResa = deepClone(ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid][ctx.modalInfos.repas] ?? {}).reverse();
      // return userResa
      const guestResa = Object.entries(ctx.data[ctx.modalInfos.date].reservation ?? {})
        .filter(([idResa, resa]) =>
          (resa?.[ctx.modalInfos.repas]?.find(_resa => _resa.isGuest) || resa?.[ctx.modalInfos.repas]?.[0]?.orderId)
          && ctx?.users[idResa]?.linkedTo === ctx?.modalInfos.uid
        )
        .map(([idResa, resa]) => ({uid: idResa, ...resa[ctx.modalInfos.repas][0]}));
      return [...userResa, ...guestResa];
    }
    if(type === "guest") {
      const resa = deepClone(ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid][ctx.modalInfos.repas])
      return resa; 
    }
    return null;
  };

  //aliases
  const dataDay = ctx?.data[ctx?.modalInfos?.date];
  const currentUser = ctx?.users[ctx?.modalInfos?.uid] ?? (ctx.guests && ctx.guests.find(guest => guest.guestId === ctx.modalInfos.uid)) ?? {};
  const subscription = findSubscriptionForDate(currentUser?.subscriptions, ctx?.modalInfos?.date);
  const isEmployee = currentUser.role === "employee";
  useEffect(() => {
    try {
      setPage(0);

      const _resa = getResa(type);
      if (_resa) {
        setReservation(_resa);

        if (_resa[0] && _resa[0].status !== "canceled") {
          setEmpty(false);
        } else {
          setEmpty(true);
        }
      } else {
        setReservation([defaultReservationObject()]);
        setEmpty(true);
      }

      if (type === "guest" && currentUser.type !== "establishmentGuest") {
        setHasPaid(_resa[page].hasPaid ?? false);
      }

    } catch (error) {
      console.error("error", error)
      setReservation([defaultReservationObject()]);
      setEmpty(true);
      setPage(0);
    }
  }, [ctx.modalResaOpen, type]);


  const defaultReservationObject = () => {
    if (template && ctx?.modalInfos?.repas && template[ctx.modalInfos.repas].categories) {
      const _defaultMenu = {};

      template[ctx.modalInfos.repas].categories.map((_categ) => _defaultMenu[_categ] = null);
      _defaultMenu["supplément"] = null;
      _defaultMenu["suppléments restaurant"] = null;

      const _resaObj = {
        ..._defaultMenu,
        homeDelivery: reservation?.[0]?.homeDelivery ?? false,
        freeHomeDelivery: currentUser?.freeHomeDelivery ?? false,
        ...(currentUser.role === "guest" && { isGuest: true }),
        ...(currentUser.role === "guest" && { uid: ctx?.modalInfos?.uid  })

      };
      return _resaObj;

    } else {
      return {
        homeDelivery: false
      };
    }
  };

  const _save = () => {
    ctx.updateMenu(reservation);
    const _initial = ctx.data[ctx.modalInfos.date] && ctx.data[ctx.modalInfos.date].reservation && ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid] ? ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid][ctx.modalInfos.repas] ?? [] : [];
    const dateFinal = new Date(ctx?.modalInfos?.date);
    // date.setFullYear(year,month,day);
    if(_initial.length === 0 ){
      sendNotifToCentreNotif({
      ui,
      data: {
        type: "menu",
        action: "add",
        data: {
          homeDelivery: reservation[0]?.homeDelivery,
          quantity: reservation?.length,
          userId: currentUser.uid,
          name: ctx?.modalInfos?.repas,
          date: dateFinal,
        }
      }
    }); 
    }else{
      sendNotifToCentreNotif({
      ui,
      data: {
        type: "menu",
        action: "update",
        data: {
          homeDelivery: reservation[0]?.homeDelivery,
          quantity: reservation?.length,
          userId: currentUser.uid,
          name: ctx?.modalInfos?.repas,
          date: dateFinal,
        }
      }
    }); }
    
    dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
  };

  const _delete = () => {
    ctx.updateMenu("delete");
    const dateFinal = new Date(ctx?.modalInfos?.date);
    sendNotifToCentreNotif({
      ui,
      data: {
        type: "menu",
        action: "delete",
        data: {
          homeDelivery: reservation[0]?.homeDelivery,
          quantity: reservation?.length,
          userId: currentUser.uid,
          name: ctx?.modalInfos?.repas,
          date: dateFinal,
        }
      }
    }); 
    dispatch({ type: "setProperty", property: "modalResaOpen", value: false });
  };


  const updateHomeDelivery = () => {
    let currentValue = reservation[0].homeDelivery;

    let _resa = deepClone(reservation);

    _resa.forEach(element => {
      element.homeDelivery = !currentValue;
    });

    setReservation(_resa);
  };

  const updateFreeHomeDelivery = () => {
    let currentValue = reservation[0].freeHomeDelivery;

    let _resa = deepClone(reservation);

    _resa.forEach(element => {
      element.freeHomeDelivery = !currentValue;
    });

    setReservation(_resa);
  };

  const updatePaid = () => {
    const _currentResa = deepClone(reservation);

    _currentResa[page].hasPaid = !hasPaid;
    
    setHasPaid(!hasPaid);

    // Paiement commande en restaurant
    if(reservation[page]?.orderId) ctx.updateGuestPaid(reservation[page]);

    // Paiement reservation
    if(!reservation[page]?.orderId) {
      _currentResa[page].hasPaid = !hasPaid ?? true;

      setReservation(_currentResa);
    }
  };

  const updateSelection = (category, choice) => {
    let currentResa = deepClone(reservation);

    currentResa[page][category] = choice;

    setReservation(currentResa);
  };

  const addResa = (uid) => {
    if(reservation.find(resa => resa.uid === uid)) return;
    let currentResa = deepClone(reservation);

    currentResa.push({
      ...defaultReservationObject(),
      isGuest: true,
      uid: uid,
    });

    setReservation(currentResa);
    setPage(currentResa.length - 1);
  };

  const removeResa = () => {
    if (page > 0 && page < reservation.length) {
      let currentResa = deepClone(reservation);

      currentResa.splice(page, 1);

      setReservation(currentResa);
      setPage(page - 1);
    }
  };

  const hasChanged = useMemo(() => {
    let _initial = []
    // On compare la resa actuelle à la resa initiale :
    if(ctx.data[ctx.modalInfos.date] && ctx.data[ctx.modalInfos.date].reservation && ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid] && ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid][ctx.modalInfos.repas]) {
      const userResa = deepClone(ctx.data[ctx.modalInfos.date].reservation[ctx.modalInfos.uid][ctx.modalInfos.repas]).reverse();
      // return userResa
      const guestResa = Object.entries(ctx.data[ctx.modalInfos.date].reservation ?? {})
        .filter(([idResa, resa]) =>
          (resa?.[ctx.modalInfos.repas]?.find(_resa => _resa.isGuest) || resa?.[ctx.modalInfos.repas]?.[0]?.orderId)
          && ctx?.users[idResa]?.linkedTo === ctx?.modalInfos.uid
        )
        .map(([idResa, resa]) => ({uid: idResa, ...resa[ctx.modalInfos.repas][0]}));
        _initial = [...userResa, ...guestResa];
    }
    

    if (deepEqual(_initial, reservation)) {
      return false;
    } else {
      return true;
    }
  }, [reservation, ctx]);

  return (
    <Modal isOpen={ctx.modalResaOpen} toggle={() => dispatch({ type: "setProperty", property: "modalResaOpen", value: false })} className={styles.modal}>
      <ModalHeader className={styles.modalHeader}>
        <div className={styles.modalHeaderComponent}>

          <div className={styles.modalTitle}>
            Réservation pour le {ctx?.modalInfos?.repas} du <span className={styles.headerDate}>{(ctx?.modalInfos?.date ?? "").split("-").reverse().join("/")}</span>
          </div>

          <div className={styles.headerContent}>
            {page === 0 && templateSubscription ?
              <Tag className={styles.tag} style={{ backgroundColor: subscription?.subscription ? colors[Object.keys(templateSubscription).indexOf(subscription?.subscription) % 5] : colorUnite }}><div>{subscription?.subscription ?? "Unité"}</div></Tag>
              : null}
          </div>

        </div>
        
      </ModalHeader>
      <ModalBody style={{ padding: 0 }}>
        <div className={styles.headerResa}>
          <div className={styles.resaList}>
            <div className={page === 0 ? styles.resaSelected : null} onClick={() => setPage(0)}> {currentUser?.surname} {currentUser?.name}</div>
            {reservation.length > 1 ?
              reservation.map((_r, indx) => (
                indx > 0 ?
                  <div key={indx} onClick={() => setPage(indx)} className={page === indx ? styles.resaSelected : null}>
                    <div>
                      {ctx?.users[_r?.uid]?.name} {ctx?.users[_r?.uid]?.surname} 
                    </div>
                  </div>
                  : null
              ))
              : null}
          </div>
          <div className={styles.resaButton}>
              {!isEmployee && type !== "guest"?
                <>
                  {page > 0 ? <div color="danger" onClick={removeResa} style={{ color: "red", textAlign: "center" }}> <FontAwesomeIcon icon={faUserMinus} color="red" /> Supprimer cette invitation</div> : null}
                  <div 
                    onClick={() => {
                      setGuestSelectorModalProps({
                        ...guestSelectorModalProps, 
                        linkedTo: ["seniorTotem","senior"].includes(currentUser.role) ? currentUser.uid : null, 
                        date: ctx?.modalInfos?.date, 
                        service: ctx.modalInfos.repas, 
                        guestTypeFilter: ["guest", "establishmentGuest"],
                        onSubmit: (guest) => addResa(guest.uid),
                      }); 
                      setIsGuestSelectorModalOpen(true); 
                    }} 
                    style={{textAlign: "center"}} 
                  > 
                    <FontAwesomeIcon icon={faUserPlus} color="#300438" /> Inviter une personne
                  </div>
                </>
                : null}
          </div>
        </div>
        
        <div className={styles.modalContainer}>
          <ModalInformation reservation={reservation} page={page} />
          <div className={styles.contentHorizontal}>
            <div className={styles.contentVertical}>
              <div className={styles.content}>
                {reservation ?
                  <CategorySelector page={page} dataReservation={ reservation[page]?.plates ? {...reservation[page]?.plates, "suppléments restaurant": ctx.dataOrdersSupplements?.[ctx.modalInfos.date]?.[page === 0 ? ctx?.modalInfos?.uid : reservation[page]?.uid ?? ctx?.modalInfos?.uid]?.[ctx.modalInfos.repas]} : reservation[page]} updateSelection={(category, choice) => updateSelection(category, choice)} />
                  : null}
              </div>
              <SignatureDisplay showSignature={showSignature} page={page}reservation={reservation} />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={styles.footer}>

          {type === "guest" &&
            <>
              
              {currentUser.type !== "establishmentGuest" &&
                  <Checkbox className={`${styles.checkbox} ${styles2.checkbox}`} label="Repas réglé" checked={hasPaid} onChange={updatePaid} />
                }
              
              { !reservation[page]?.orderId  && 
                <div className={styles.footerButtons} style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "space-around", gap: 10 }}>
                  {!empty ?
                    <Button color="danger" onClick={_delete}>{reservation && reservation[0] && reservation[0].createdLocally ? "Annuler la réservation automatique" : "Supprimer"}</Button>
                    : null}
                  {dataDay ? <Button color="primary" disabled={!hasChanged} onClick={_save}>Valider la réservation</Button> : null}
                  <Button color="secondary" onClick={() => dispatch({ type: "setProperty", property: "modalResaOpen", value: false })}>Annuler</Button>
                  {reservation[page]?.hasSignature && <Button onClick={() => setShowSignature(!showSignature)}>{showSignature ? "Cacher la signature" : "Voir la signature"}</Button>}
                </div>
              }
            </>

          }
          {type !== "guest" &&
            <>
              <div>
                <Checkbox className={styles.checkbox} label="portage" checked={reservation ? reservation[0]?.homeDelivery : false} onChange={updateHomeDelivery} />
                {reservation[0]?.homeDelivery && <Checkbox className={styles.checkbox} label="Portage non facturé" checked={reservation ? reservation[0]?.freeHomeDelivery : false} onChange={updateFreeHomeDelivery} />}
              </div>
              <div className={styles.footerButtons} style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                {!empty ?
                  <Button color="danger" onClick={_delete}>{reservation && reservation[0] && reservation[0].createdLocally ? "Annuler la réservation automatique" : "Supprimer"}</Button>
                  : null}
                <Button color="secondary" onClick={() => dispatch({ type: "setProperty", property: "modalResaOpen", value: false })}>Annuler</Button>
                {dataDay ? <Button color="primary" disabled={!hasChanged} onClick={_save}>Valider la réservation</Button> : null}
                {reservation[page]?.hasSignature && <Button onClick={() => setShowSignature(!showSignature)}>{showSignature ? "Cacher la signature" : "Voir la signature"}</Button>}
              </div>
            </>
          }
          <div className={styles.nbresas}>
            Total : {reservation ? reservation.length : 0} repas
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const SuspenseHOC = (props) => {
  const [ctx] = useMenuReservation();

  if (!ctx || !ctx.data || !ctx.modalInfos) return <></>;
  return <ModalReservation {...props} />;
};


ModalReservation.propTypes = {
  type: PropTypes.string,
};

export default SuspenseHOC;
