import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";


const notificationsStore = create(subscribeWithSelector((set) => ({
    notifications: [],
    setNotifications: (notifications) => set(({ notifications })),

    permission: [],
    setPermission: (permission) => set(({ permission })),

    alreadySent: [],
    setAlreadySent: (alreadySent) => set(({ alreadySent })),

    notificationRequired :{},
    setNotificationRequired :(notificationRequired) => set(({ notificationRequired })),

    nombreNotif: {},
    setNombreNotif : (nombreNotif) => set(({nombreNotif})),

    unsubscribe: null,
    setUnsubscribe : (unsubscribe) => set(({unsubscribe})),

    needSubscribe: true,
    setNeedSubscribe : (needSubscribe) => set(({needSubscribe})),

})));


export default notificationsStore;